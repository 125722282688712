import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public token: any;
  public apiUrl;

  constructor(private http: HttpClient, private tokenService: TokenService) {
    this.apiUrl = environment.apiUrl;
    this.tokenService.token$.subscribe((res: any) => {
      this.token = res;
    });
  }

  post(serviceName: string, data: any) {
    const options = { headers: this.getHeader(), withCredintials: false };
    return this.http.post(
      this.apiUrl + serviceName,
      data ? JSON.stringify(data) : null,
      options
    );
  }

  put(serviceName: string, data: any) {
    const options = { headers: this.getHeader(), withCredintials: false };
    return this.http.put(
      this.apiUrl + serviceName,
      JSON.stringify(data),
      options
    );
  }

  get(serviceName: string, params?: HttpParams) {
    const options = {
      headers: this.getHeader(),
      withCredintials: false,
      params,
    };
    return this.http.get(this.apiUrl + serviceName, options);
  }

  getHeader() {
    return new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Accept', '*/*')
      .set('Content-Type', 'application/json')
      .set('Accept-Encondig', 'gzip, deflate, br')
      .set('Authorization', this.token?.token || '');
  }
}
