import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MegaMenuModule } from 'primeng/megamenu';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminTransactionsComponent } from './pages/home/admin/admin-transactions/admin-transactions.component';
import { AdminUsersComponent } from './pages/home/admin/admin-users/admin-users.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { MenuBarComponent } from './shared/components/menu-bar/menu-bar.component';
import { ProgressSpinnerComponent } from './shared/components/progress-spinner/progress-spinner.component';
import { SalesComponent } from './pages/home/partner/sales/sales.component';
import { BirthdaysComponent } from './pages/home/partner/birthdays/birthdays.component';
import { PrimeCustomersComponent } from './pages/home/partner/prime-customers/prime-customers.component';
import { HistoricComponent } from './pages/home/partner/historic/historic.component';
import { SupportComponent } from './pages/home/partner/support/support.component';
import { SettingsComponent } from './pages/home/partner/settings/settings.component';
registerLocaleData(ptBr);
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ProgressSpinnerComponent,
    ConfirmDialogComponent,
    AdminUsersComponent,
    AdminTransactionsComponent,
    MenuBarComponent,
    SalesComponent,
    BirthdaysComponent,
    PrimeCustomersComponent,
    HistoricComponent,
    SupportComponent,
    SettingsComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CalendarModule,
    ConfirmDialogModule,
    ChartModule,
    DialogModule,
    DropdownModule,
    FormsModule,
    HttpClientModule,
    MegaMenuModule,
    InputTextModule,
    PasswordModule,
    ProgressSpinnerModule,
    TableModule,
    TagModule,
    ToastModule,
    TooltipModule,
  ],
  providers: [
    HttpClientModule,
    ConfirmationService,
    MessageService,
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
