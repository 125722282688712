import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { Table } from 'primeng/table';
import { DashboardService } from 'src/app/core/services/dashboard/dashboard.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { ToastService } from '../../../../core/services/toast/toast.service';
import { TransactionService } from '../../../../core/services/transaction/transaction.service';
import { DashboardTransactions } from '../../../../shared/models/dashboard/dashboard-transactions';
import { TransactionTypes } from '../../../../shared/models/transaction-types';

@Component({
  selector: 'app-admin-transactions',
  templateUrl: './admin-transactions.component.html',
  styleUrls: ['./admin-transactions.component.scss'],
})
export class AdminTransactionsComponent {
  public dashboardTransactions: DashboardTransactions =
    new DashboardTransactions();

  public cols: any[] = [];
  public rangeDates: Date[] = [];
  public typeSelected: TransactionTypes = new TransactionTypes();
  public transactionTypes: TransactionTypes[] = [];

  constructor(
    private dashboardService: DashboardService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private transactionService: TransactionService
  ) {
    const dtStart: Date = new Date();
    dtStart.setDate(new Date().getDate() - 14);
    this.rangeDates.push(dtStart, new Date());
  }

  async ngOnInit() {
    try {
      await this.loaderService.setLoading(true);
      this.transactionTypes = await this.transactionService.types();
      this.typeSelected =
        this.transactionTypes.slice().shift() || new TransactionTypes();
      await this.getAllTransactions();

      this.cols = [
        { field: 'currentDate', header: 'Data' },
        { field: 'user.name', header: 'Usuário Recebeu' },
        { field: 'userFrom.name', header: 'Usuário Pagou' },
        { field: 'originalValue', header: 'Valor original' },
        { field: 'value', header: 'Valor' },
        { field: 'type', header: 'Tipo' },
        { field: 'transactionType', header: 'Crédito' },
      ];
    } catch (error) {
      console.error(error);
    } finally {
      await this.loaderService.setLoading(false);
    }
  }

  async getAllTransactions() {
    if (!this.rangeDates[0] || !this.rangeDates[1]) {
      this.toastService.error(
        'Campo obrigatório',
        'Por favor, informe data início e data fim!'
      );
      return;
    }
    try {
      await this.loaderService.setLoading(true);
      const dtStart = formatDate(this.rangeDates[0], 'yyyy-MM-dd', 'pt');
      const dtEnd = formatDate(this.rangeDates[1], 'yyyy-MM-dd', 'pt');
      this.dashboardTransactions = await this.dashboardService.allTransactions(
        dtStart,
        dtEnd,
        this.typeSelected?.id || ' '
      );
    } catch (error) {
      console.error(error);
    } finally {
      await this.loaderService.setLoading(false);
    }
  }

  onChangeTransaction(evt: any) {
    this.typeSelected = evt.value;
    this.getAllTransactions();
  }

  onChangeDates() {
    this.getAllTransactions();
  }

  setPie() {}

  clear(table: Table) {
    table.clear();
  }
}
