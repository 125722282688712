import { Injectable } from '@angular/core';
import { TransactionTypes } from 'src/app/shared/models/transaction-types';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(private httpService: HttpService) {}

  types(): Promise<TransactionTypes[]> {
    const list: TransactionTypes[] = [];
    return new Promise((resolve, reject) => {
      this.httpService.get(`macros/transaction-types`).subscribe({
        next: (res: any) => {
          if (res) {
            list.push(
              Object.assign(new TransactionTypes(), {
                id: '',
                name: 'Todas Transações',
              })
            );
            let newTrans;
            Object.keys(res).map((key) => {
              newTrans = Object.assign(new TransactionTypes(), res[key]);
              newTrans.name = newTrans.id;
              list.push(newTrans);
            });
            resolve(list);
          }
        },
        error: (error) => {
          console.error(error);
          reject();
        },
      });
    });
  }
}
