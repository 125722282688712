import { Component } from '@angular/core';

@Component({
  selector: 'app-prime-customers',
  templateUrl: './prime-customers.component.html',
  styleUrls: ['./prime-customers.component.scss']
})
export class PrimeCustomersComponent {

}
