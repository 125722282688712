export class TransactionTypes {
  public static readonly TRANSACTION_BONUS = 'indicator_bonus';
  public static readonly TRANSACTION_CASHBACK = 'cashback';
  public static readonly TRANSACTION_PREMIUM_CASHBACK = 'premium_cashback';
  public static readonly TRANSACTION_PAYMENT = 'payment';
  public static readonly TRANSACTION_CHARGE = 'charge';
  public static readonly TRANSACTION_WITHDRAW = 'withdraw';
  public static readonly TRANSACTION_TRANSFER = 'transfer';
  public static readonly TRANSACTION_PREMIUM_UPGRADE = 'premium_upgrade';

  id?: string;
  name?: string;
}
