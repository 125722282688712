import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { USER_ADMIN, User } from 'src/app/shared/models/user/user';
import { HttpService } from '../http/http.service';
import { TokenService } from '../token/token.service';
import { UserService } from '../user/user.service';
import { AuthConstants } from './../../config/auth-constants';
import { LocalStorageService } from './../storage/local/local-storage.service';
import { ToastService } from './../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private httpService: HttpService,
    private localStorageService: LocalStorageService,
    private toastService: ToastService,
    private tokenService: TokenService,
    private router: Router,
    private userService: UserService
  ) {}

  login(postData: any): Promise<any> {
    const user: User = new User();
    return new Promise((resolve, reject) => {
      this.httpService.post('auth', postData).subscribe({
        next: (res: any) => {
          if (res.user) {
            user.fromJson(res.user);
            this.localStorageService.set(AuthConstants.TOKEN, res.tokenRow);
            this.tokenService.token$.next(res.tokenRow);
            if (user?.id === USER_ADMIN) {
              this.router.navigate(['home/admin/usuarios']);
            } else {
              this.router.navigate(['home/parceiro/vendas']);
            }
            resolve(user);
          } else {
            this.toastService.error(
              'Credenciais inválidas',
              'As credenciais informadas são inválidas!'
            );
          }
        },
        error: (error) => {
          const msg =
            error?.error?.msg ||
            error?.error?.error ||
            'Ocorreu um erro ao fazer seu login.';
          this.toastService.error('Erro ao efetuar login', msg);
          reject(false);
        },
      });
    });
  }

  logout() {
    this.localStorageService.clear().then((_res) => {
      this.userService.userData$.next('');
      this.tokenService.token$.next('');
      this.router.navigate(['login']);
    });
  }
}
