import { User } from './../user/user';
export class DashboardUsers {
  usersAll?: User[];
  usersCompanies?: User[];
  usersCompaniesFree?: User[];
  usersCompaniesPrime?: User[];
  usersNormal?: User[];
  usersNormalFree?: User[];
  usersNormalPrime?: User[];
  totalUsersNormalPrime = 0;
  totalUsersNormalFree = 0;
  totalUsersCompaniesPrime = 0;
  totalUsersCompaniesFree = 0;
}
