<p-chart
  type="pie"
  [data]="data"
  [style]="{ width: '30%' }"
  (onDataSelect)="onDataSelect($event)"
></p-chart>
<div class="home-users-grid">
  <p-table
    [columns]="colsUser"
    #tableusers
    [value]="dashboardSelected"
    styleClass="p-datatable-gridlines"
    [scrollable]="true"
    selectionMode="single"
    scrollHeight="calc(60vh - 10px)"
    (onRowUnselect)="onRowUnselect()"
    [(selection)]="selectedUser"
    [globalFilterFields]="[
      'name',
      'email',
      'phone',
      'address.city.name',
      'onHold',
      'createdAt'
    ]"
  >
    <ng-template pTemplate="caption">
      <div class="table-header-search">
        {{ getTitleGrid() }}

        <div class="icons">
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-file"
            (click)="tableusers.exportCSV()"
            class="mr-2"
            pTooltip="Exportar para CSV"
            tooltipPosition="bottom"
          ></button>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input
              pTooltip="Pesquise qualquer campo do grid"
              tooltipPosition="bottom"
              pInputText
              type="text"
              (input)="
                tableusers.filterGlobal($any($event.target).value, 'contains')
              "
              placeholder="Pesquisar"
            />
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">
          Nome<p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="createdAt">
          Cadastro<p-sortIcon field="createdAt"></p-sortIcon>
        </th>
        <th pSortableColumn="birth">
          {{
            dashboardSelectedIndex === 0 || dashboardSelectedIndex === 1
              ? "Nascimento"
              : "Abertura"
          }}<p-sortIcon field="birth"></p-sortIcon>
        </th>
        <th pSortableColumn="phone">
          Telefone<p-sortIcon field="phone"></p-sortIcon>
        </th>
        <th
          pSortableColumn="planCreatedAt"
          *ngIf="
            this.dashboardSelectedIndex === 1 ||
            this.dashboardSelectedIndex === 3
          "
        >
          Prime<p-sortIcon field="planCreatedAt"></p-sortIcon>
        </th>
        <th pSortableColumn="address.city.name">
          Cidade<p-sortIcon field="address.city.name"></p-sortIcon>
        </th>
        <th pSortableColumn="email">
          E-mail<p-sortIcon field="email"></p-sortIcon>
        </th>
        <th pSortableColumn="onHold">
          Confirmado<p-sortIcon field="onHold"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr [pSelectableRow]="user">
        <td class="name">{{ user?.name }}</td>
        <td>{{ user?.createdAt | date : "shortDate" }}</td>
        <td>{{ user?.birth | date : "shortDate" }}</td>
        <td>{{ user?.phone }}</td>
        <td
          *ngIf="
            this.dashboardSelectedIndex === 1 ||
            this.dashboardSelectedIndex === 3
          "
        >
          {{ user?.planCreatedAt | date : "shortDate" }}
        </td>
        <td>{{ user?.address?.city?.name }}</td>
        <td>{{ user?.email }}</td>
        <td class="onhold">
          <i *ngIf="!user.onHold" class="pi pi-check" style="color: green"></i>
          <i *ngIf="user.onHold" class="pi pi-times" style="color: red"></i>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      Total: {{ dashboardSelected.length }} usuários
      <div>
        <button
          pButton
          pRipple
          [label]="'Indicados'"
          icon="pi pi-list"
          (click)="onClickIndications()"
        ></button>
        <button
          pButton
          pRipple
          [label]="'Alterar'"
          icon="pi pi-file-edit"
          (click)="onClickChange()"
        ></button>
      </div>
    </ng-template>
  </p-table>
</div>
<p-dialog
  header="Usuários indicados por: {{ selectedUser.name || '' }}"
  [(visible)]="showIndications"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false"
>
  <p-table
    #tableusersindications
    [columns]="colsIndications"
    [value]="usersIndications"
    styleClass="p-datatable-gridlines"
    [scrollable]="true"
    scrollHeight="50vh"
    [globalFilterFields]="['name', 'email', 'phone']"
  >
    <ng-template pTemplate="caption">
      <div class="table-header-search">
        <div class="icons">
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-file"
            (click)="tableusersindications.exportCSV()"
            class="mr-2"
            pTooltip="Exportar para CSV"
            tooltipPosition="bottom"
          ></button>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input
              pTooltip="Pesquise qualquer campo do grid"
              tooltipPosition="bottom"
              pInputText
              type="text"
              (input)="
                tableusersindications.filterGlobal(
                  $any($event.target).value,
                  'contains'
                )
              "
              placeholder="Pesquisar"
            />
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">
          Nome<p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="email">
          E-mail<p-sortIcon field="email"></p-sortIcon>
        </th>
        <th pSortableColumn="phone">
          Telefone<p-sortIcon field="phone"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr>
        <td class="name">{{ user?.name }}</td>
        <td>{{ user?.email }}</td>
        <td>{{ user?.phone }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      Total: {{ usersIndications.length }} usuários
    </ng-template>
  </p-table>
</p-dialog>

<p-dialog
  header="{{ selectedUser.name || '' }}"
  [(visible)]="showChange"
  [modal]="true"
  [style]="{ width: '500px' }"
  [draggable]="false"
  [resizable]="false"
>
  <form>
    <div>
      <input
        type="email"
        name="email"
        pInputText
        [(ngModel)]="selectedUser.email"
        [ngClass]="{
          'ng-invalid': !selectedUser.email,
          'ng-dirty': !selectedUser.email
        }"
        pInputText
      />
      <small *ngIf="!selectedUser.email" class="p-error block"
        >Informe seu e-mail.</small
      >
    </div>
    <button (click)="onClickChangeEmail()">Alterar E-mail</button>
  </form>
  <form *ngIf="selectedUser.onHold">
    <p-tag [value]="'USUÁRIO NÃO CONFIRMADO'" [severity]="'danger'"></p-tag>
    <button (click)="onClickConfirmUser()">Confirmar Usuário</button>
  </form>
</p-dialog>
