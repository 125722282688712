import { Injectable } from '@angular/core';
import { UserService } from '../../services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserResolver {
  constructor(private userService: UserService) {}

  resolve() {
    return this.userService.getUserData();
  }
}
