import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MegaMenuItem } from 'primeng/api';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { USER_ADMIN, User } from 'src/app/shared/models/user/user';
import { AuthService } from './../../core/services/auth/auth.service';
import { DashboardService } from './../../core/services/dashboard/dashboard.service';
import { UserService } from './../../core/services/user/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public itemsMenu: MegaMenuItem[] = [];
  public user: User = new User();

  constructor(
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private dashboardService: DashboardService,
    private loaderService: LoaderService,
    private userService: UserService
  ) {}

  async ngOnInit() {
    try {
      await this.loaderService.setLoading(true);
      this.user = await this.userService.getUser();
      this.setMenu();
    } catch (error) {
      console.error(error);
    } finally {
      await this.loaderService.setLoading(false);
    }
  }

  setMenu() {
    if (this.user.id === USER_ADMIN) {
      this.setMenuAdmin();
    } else {
      this.setMenuPartner();
    }
  }

  setMenuAdmin() {
    this.itemsMenu = [
      {
        label: 'Usuários',
        icon: 'pi pi-fw pi-users',
        routerLink: 'admin/usuarios',
      },
      {
        label: 'Transações',
        icon: 'pi pi-fw pi-dollar',
        routerLink: 'admin/transacoes',
      },
      {
        label: 'Sair',
        icon: 'pi pi-fw pi-power-off',
        command: () => this.logout(),
      },
    ];
  }

  setMenuPartner() {
    this.itemsMenu = [
      {
        label: 'Vendas por Dia',
        icon: 'pi pi-fw pi-chart-bar',
        badge: '10',
        routerLink: 'parceiro/vendas',
      },
      {
        label: 'Maiores Vendas',
        icon: 'pi pi-fw pi-chart-line',
        routerLink: 'parceiro/vendas',
      },
      {
        label: 'Aniversariantes',
        icon: 'pi pi-fw pi-megaphone',
        badge: '2',
        routerLink: 'parceiro/aniversariantes',
      },
      {
        label: 'Clientes Prime',
        icon: 'pi pi-fw pi-users',
        badge: '25',
        routerLink: 'parceiro/clientes-prime',
      },
      {
        label: 'Histórico de Envios',
        icon: 'pi pi-fw pi-book',
        routerLink: 'parceiro/historico',
      },
      {
        label: 'Suporte',
        icon: 'pi pi-fw pi-phone',
        routerLink: 'parceiro/suporte',
      },
      {
        label: 'Configurações',
        icon: 'pi pi-fw pi-cog',
        routerLink: 'parceiro/configuracoes',
      },
      {
        label: 'Sair',
        icon: 'pi pi-fw pi-power-off',
        command: () => this.logout(),
      },
    ];
  }

  async logout() {
    this.confirmationService.confirm({
      message: 'Deseja realmente sair?',
      header: 'Confirmação',
      accept: async () => {
        await this.loaderService.setLoading(true);
        setTimeout(async () => {
          this.authService.logout();
          await this.loaderService.setLoading(false);
        }, 500);
      },
    });
  }
}
