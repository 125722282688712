import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private storage: Storage;

  constructor() {
    this.storage = localStorage;
  }

  async set(key: string, value: any) {
    const encryptedValue = btoa(
      unescape(encodeURIComponent(JSON.stringify(value)))
    );
    await this.storage.setItem(key, encryptedValue);
  }

  async get(key: string) {
    const res = await this.storage.getItem(key);
    if (res) {
      const decrypted = decodeURIComponent(escape(atob(res)));
      return JSON.parse(decrypted);
    } else {
      return false;
    }
  }

  async removeItem(key: string) {
    await this.storage.removeItem(key);
  }

  async clear() {
    await this.storage.clear();
  }
}
