import { User } from '../user/user';
export class DashboardTransactionsDetails {
  currentDate?: Date;
  id?: number;
  onHold?: boolean;
  originalValue?: number;
  user?: User;
  userFrom?: User;
  userFromId?: number;
  userId?: number;
  transactionType?: string;
  type?: string;
  value?: number;
}
