<div class="home-transactions-grid">
  <p-table
    [columns]="cols"
    #tableusers
    [value]="dashboardTransactions.transactions || []"
    styleClass="p-datatable-gridlines"
    [scrollable]="true"
    scrollHeight="calc(60vh - 10px)"
    [globalFilterFields]="[
      'currentDate',
      'transactionType',
      'type',
      'user.name',
      'userFrom.name'
    ]"
  >
    <ng-template pTemplate="caption">
      <div class="table-header-search">
        <div class="icons">
          <p-calendar
            [(ngModel)]="rangeDates"
            (onClickOutside)="onChangeDates()"
            selectionMode="range"
            [readonlyInput]="true"
            [showIcon]="true"
            dateFormat="dd/mm/yy"
          ></p-calendar>
          <p-dropdown
            [options]="transactionTypes"
            (onChange)="onChangeTransaction($event)"
            optionLabel="name"
          ></p-dropdown>
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input
              pTooltip="Pesquise qualquer campo do grid"
              tooltipPosition="bottom"
              pInputText
              type="text"
              (input)="
                tableusers.filterGlobal($any($event.target).value, 'contains')
              "
              placeholder="Pesquisar"
            />
          </span>
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-file"
            (click)="tableusers.exportCSV()"
            class="btn-csv"
            pTooltip="Exportar para CSV"
            tooltipPosition="bottom"
          ></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="currentDate">
          Data<p-sortIcon field="currentDate"></p-sortIcon>
        </th>
        <th pSortableColumn="trans.user.name">
          Usuário Recebeu<p-sortIcon field="trans.user.name"></p-sortIcon>
        </th>
        <th pSortableColumn="trans.userFrom.name">
          Usuário Pagou<p-sortIcon field="trans.userFrom.name"></p-sortIcon>
        </th>
        <th pSortableColumn="originalValue">
          Valor original<p-sortIcon field="originalValue"></p-sortIcon>
        </th>
        <th pSortableColumn="value">
          Valor<p-sortIcon field="value"></p-sortIcon>
        </th>
        <th pSortableColumn="type">
          Tipo<p-sortIcon field="type"></p-sortIcon>
        </th>
        <th pSortableColumn="transactionType">
          Crédito<p-sortIcon field="transactionType"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-trans>
      <tr>
        <td>{{ trans?.currentDate | date : "dd/MM/yyyy HH:mm:ss" }}</td>
        <td class="name">{{ trans?.user?.name }}</td>
        <td class="name">{{ trans?.userFrom?.name }}</td>
        <td>{{ trans?.originalValue / 100 || 0 }}</td>
        <td>{{ trans?.value / 100 }}</td>
        <td>{{ trans?.type }}</td>
        <td>{{ trans?.transactionType }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      Total: {{ dashboardTransactions.transactions?.length }} transações
    </ng-template>
  </p-table>
</div>
