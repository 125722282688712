export const GROUP_SEPARATOR = ',';
export const val = '';
export const v = '';

export const formatPhone = (phone: string): string => {
  if (!phone) {
    return '';
  }
  return phoneMask(phone);
};

export const phoneMask = (value: any): string => {
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{0})(\d)/, '$1($2');
  value = value.replace(/(\d{2})(\d)/, '$1)$2');
  value = value.replace(/(\d{3})(\d{3,4})$/, '$1-$2');
  return value;
};
