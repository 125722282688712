import { Component, Input } from '@angular/core';
import packageJson from 'package.json';
import { MegaMenuItem } from 'primeng/api';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
})
export class MenuBarComponent {
  @Input()
  public itemsMenu: MegaMenuItem[] = [];

  public version: string = packageJson.version;

  constructor() {}
}
