import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  postData = {
    email: '',
    password: '',
  };

  public valid = false;

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private toastService: ToastService
  ) {}

  validateInputs() {
    this.valid = true;
    return (
      this.postData?.email?.trim()?.length > 0 &&
      this.postData?.password?.trim()?.length > 0
    );
  }

  async onClickLogin() {
    await this.loaderService.setLoading(true);
    if (this.validateInputs()) {
      try {
        await this.authService.login(this.postData);
      } catch (error) {
        console.error(error);
      } finally {
        await this.loaderService.setLoading(false);
      }
    } else {
      await this.loaderService.setLoading(false);
      this.toastService.error(
        'Campo obrigatório',
        'Por favor, informe seu e-mail e sua senha!'
      );
    }
  }

  get passwordInvalid(): boolean {
    return !this.postData.password && this.valid;
  }
}
