<div *ngIf="user.name" class="home">
  <app-menu-bar [itemsMenu]="itemsMenu"></app-menu-bar>

  <div class="home-main">
    <div class="home-main-header">
      <h1>Olá, {{ user.name }}</h1>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
